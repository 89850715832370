<template>
  <div class="not-available">
    <div class="not-available__image">
      <img src="/images/not-available.png" alt="">
    </div>
    <div class="not-available__title">{{ $t('not-available.title') }}</div>
    <div class="not-available__text">{{ $t('not-available.text') }}</div>
  </div>
</template>

<style scoped lang="scss" src="./NotAvailable.scss" />
