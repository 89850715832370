<template>
  <div class="not-available">
    <div class="not-available__image">
      <img src="/images/no-plan.png" alt="">
    </div>
    <div class="not-available__title">{{ $t('no-plan.title') }}</div>
    <div class="not-available__text">{{ $t('no-plan.text') }}</div>
  </div>
</template>

<style scoped lang="scss" src="./NoPlan.scss" />
