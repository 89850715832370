import axios from '@/plugins/axios';
import { useEditorStore } from '../index';

export const trimTransparent = async (image: string, sourceType = 'base64'): Promise<any> => new Promise((resolve, reject) => {
  const editorStore = useEditorStore();
  editorStore.loading = true;
  axios.post('widget/trim-transparent', { image, sourceType })
    .then((resp) => {
      const base64Image = resp.data.data.image;
      const formattedImage = `data:image/png;base64,${base64Image}`;
      resolve(formattedImage);
    })
    .catch((e) => {
      reject(e.response.data);
    })
    .finally(() => {
      editorStore.loading = false;
    });
});
